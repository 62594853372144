<template>
    <EntitySingle
      :config="config"
      :entity.sync="entity"
      :loaded.sync="entityLoaded"
      :edit-mode="false"
      :submit-callback="submitCallback"
    >
    <div v-if="entityLoaded" class="table-responsive">
      <table class="table mt-2 mt-xl-0 w-100">
        <tbody>
          <tr>
            <th class="font-weight-bold col-md-3">{{ $t('Subscription Name') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" class="col-md-3">{{ entity.userGroup ? entity.userGroup.title : '' }}</td>
            <th class="font-weight-bold col-md-3">{{ $t('Subscription Type') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" class="col-md-3">{{ $t(entity.type) }}</td>
          </tr>
          <tr>
            <th class="font-weight-bold col-md-3">{{ $t('Subscriber Name') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" class="col-md-3">{{ entity.purchasedUser ? entity.purchasedUser.participant.firstName + " " + entity.purchasedUser.participant.lastName : '' }}</td>
            <th class="font-weight-bold col-md-3">{{ $t('Transaction ID') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" class="col-md-3">{{ entity.paymentId }}</td>
          </tr>
          <tr>
            <th class="font-weight-bold col-md-3">{{ $t('Paid Amount') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" class="col-md-3">{{ entity.amount }} €</td>
            <th class="font-weight-bold col-md-3">{{ $t('Payment Date') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" class="col-md-3">{{ formatDateTimeNormal(entity.creationDate) }}</td>
          </tr>
          <tr>
            <th class="font-weight-bold col-md-3">{{ $t('Start Date') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" class="col-md-3">{{ formatDateTime(entity.startDate) }}</td>
            <th class="font-weight-bold col-md-3">{{ $t('Expiry Date') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" class="col-md-3">{{ formatDateTime(entity.endDate) }}</td>
          </tr>formatDateTimeNormal(entity.creationDate)
          <tr>
            <th class="font-weight-bold col-md-3">{{ $t('Company Name') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" class="col-md-3">{{ entity.company.title }}</td>
            <th class="font-weight-bold col-md-3">{{ $t('Maximum Deal Products') }}:</th>
            <td :style="{ fontWeight: 600, color: '#A7844E' }" colspan="3">{{ entity.userGroup ? entity.userGroup.maxProducts : '' }}</td>
          </tr>
        </tbody>       
      </table>
    </div>

    </EntitySingle>
  </template>
  
  <script>
  import axios from '@/libs/axios'
  import { BTab, BTabs, BRow, BCol } from 'bootstrap-vue'
  import { formUtils } from '@core/mixins/ui/forms'
  import EntitySingle from '@/layouts/entity/EntitySingle.vue'
  import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
  import UserViewUserInfoCard from '../../users/users-view/UserViewUserInfoCard.vue'
  import moment from 'moment-timezone';

  import {
    getUserRights,
    isAdmin,
    isSeller,
    isSupplier,
    canEditPrice,
    canPublishToShop,
    canUnPublishFromShop,
  } from '@/auth/utils'
  import storeModule from '../dealSubscriptionPaymentsStoreModule'
  export default {
    components: {
      BRow,
      BCol,
      EntitySingle,
      EntityEditSection,
      BTab,
      BTabs,
      // Local Components
    UserViewUserInfoCard,
    },
    mixins: [formUtils],
    data() {
      return {
        entity: {},
        entityLoaded: false,
        rightsLoaded: false,
        companyName: '',
        maxDealProducts: '',
      }
    },
    computed: {
      rights() {
      return getUserRights()
    },
    isAdmin() {
      return isAdmin()
    },
    isSupplier() {
      return isSupplier()
    },
    isSeller() {
      return isSeller()
    },
      config() {
        return {
          store: storeModule,
          module: 'subscriptions',
          endpoint: 'subscriptions',
          route: 'deal-subscription-payments',
          title: {
            single: this.$t('Payment Detail'),
            plural: this.$t('Payment Details'),
          },
        }
      },
},
    mounted() {
      if(this.isAdmin){
        this.$watch('entityLoaded', this.fetchPackageDetails);
      }
      this.$watch('entityLoaded', this.formatDateTime);

    },
    methods: {
      formatDateTime(dateTimeString) {
        const athensTime = moment(dateTimeString).tz('Europe/Athens');
        // Format the date using Moment.js format method
        athensTime.locale('el');
        const formattedDate = athensTime.format('D MMM YYYY, HH:mm:ss');
        return formattedDate;
    },
    formatDateTimeNormal(dateTimeString) {
        const athensTime = moment(dateTimeString, 'YYYY-MM-DD HH:mm:ss');
        athensTime.locale('el');
        // Format the date using Moment.js format method
        const formattedDate = athensTime.format('D MMM YYYY, HH:mm:ss');
        return formattedDate;
    },
      async fetchPackageDetails() {
        try {
          const packageId = this.entity.userGroup.id;
          const response = await axios.get(`/user-groups/${packageId}`);
          this.maxDealProducts = response.data.maxProducts;      
        } catch (error) {
          console.error('Error fetching package details:', error);
        }      
     },
      submitCallback() {
        this.$router.push({ name: 'deal-subscription-payments-list' })
      },
    },
  }
  </script>
  